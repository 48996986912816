import apiClient from '@/services/axios'

export const getGoodsReceipt = (params) => {
  return apiClient({
    method: 'get',
    url: '/goodreceive/query/good-receive',
    params,
  })
}

export const createGoodsReceipt = ({ param, data }) => {
  const path = param === 'simple_wms' ? '/wms' : ''
  return apiClient({
    method: 'post',
    url: `/goodreceive/cmd${path}/save`,
    data,
  })
}