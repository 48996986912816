<template>
  <a-modal width="800px" :visible="visible" :closable="false">
    <h5 class="mt-2 mb-3">
      List Revised Imported Items
    </h5>
    <a-spin :spinning="isLoading" size="large" tip="Sedang mengunggah file...">
      <div>
        <a-table
          :columns="columns"
          :row-key="(record, index) => index"
          :data-source="revisedData"
          :loading="isLoading"
          :pagination="false"
          :scroll="{ y: 330 }"
        >
          <template slot="status" slot-scope="text">
            <a-tag v-if="text.includes('sku_not_found')" color="red">
              SKU tidak ditemukan
            </a-tag>
            <a-tag v-if="text.includes('uom_not_valid')" color="orange">
              UOM tidak valid
            </a-tag>
            <a-tag v-if="text.includes('data_not_valid')" color="purple">
              Data tidak valid
            </a-tag>
          </template>
        </a-table>
      </div>
    </a-spin>
    <template #footer>
      <a-button key="back" class="mr-2" @click="typeof back !== 'undefined' && back(), onClose()">
        Close Without Download
      </a-button>
      <a-button type="primary" class="mr-2" @click.prevent="downloadTemplate">
        <a-icon type="download" />
        Close and Download List Revised
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import XLSX from 'xlsx';
import { columnsRevisedDataGR } from '@/data/columns'

export default {
  name: 'ModalFeedbackAfterImport',
  components: {
  },
  mixins: [getQueryParam],
  props: {
    revisedData: Array,
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
  },
  data() {
    return {
      columns: columnsRevisedDataGR,
    }
  },
  computed: {
  },
  methods: {
    downloadTemplate() {
      const flattenData = this.revisedData.map((obj) => ({
        sku: obj.sku,
        quantity: obj.quantity,
        uom: obj.uom,
      }))
      const rowData = [
        "SKU",
        "Quantity",
        "UOM",
      ]
      const worksheet = XLSX.utils.json_to_sheet(flattenData);
      const workbook = XLSX.utils.book_new()
      XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
      XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
      XLSX.writeFile(workbook,'file_revised_data_gr.xlsx')
      this.onClose()
    },
  },
}
</script>

<style lang="scss" module>
</style>